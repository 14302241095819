import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import type {VariationData, Cell} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getIconSvg} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {rgbaToHexString} from '@Utils/color.util';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {BOLD_STROKE_WIDTH_FACTOR, DEFAULT_SPACING_BETWEEN_ITEMS, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText} from '@postermywall/fabricjs-2';
import {applyToChildObjects} from '@Utils/fabric.util';
import {Layout} from './layout';

export class MenuLayout3 extends Layout {
  public layoutType: LayoutTypes.MENU_LAYOUT_3 | LayoutTypes.MENU_LAYOUT_4 = LayoutTypes.MENU_LAYOUT_3;
  declare item: MenuItem;

  public setStylesForHighlightedItems(): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Function of parent class, overridden in child class.
   * Handles positioning of items inside the view and apply styles specific to this layout.
   */
  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups(this.edgePadding / 2);
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.setExtraStyles();
    this.layoutItemsInsideGroups(this.edgePadding / 2);
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
  }

  /**
   * This function inserts the dots between the description and price then re-layout the items
   */
  setExtraStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects();
      const nameAndIcon = menuItems[0];
      const dottedText = menuItems[1];
      const price = menuItems[2];

      dottedText.set({
        text: '.',
        fill: this.item.textStyles.fill.getFill(this.item.fabricObject.width, this.item.fabricObject.height),
        fontSize: this.item.textStyles.fontSize,
      });

      const numberOfDots = Math.max(0, 1 + (this.item.fabricObject.width + this.item.xSpacing - (nameAndIcon.width + price.width)) / dottedText.width);
      dottedText.set({
        text: '.'.repeat(numberOfDots),
      });

      // set width again by subtracting overhead width from calculations above.(Needed for accurate alignment of items)
      dottedText.set({
        width: dottedText.width - (dottedText.width - (this.item.fabricObject.width + this.item.xSpacing - (nameAndIcon.width + price.width))),
      });
    }
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in view, specific to this layout.
   */
  async setItems(): Promise<void> {
    const columnMap = this.item.getColumnMap();
    const horizontalGroups: Group[] = [];
    const rows = this.item.getNoOfRows();
    const textObjectCaching = !this.item.page.poster.isHighRes;

    for (let a = 0; a < rows; a++) {
      const nameAndIcons = new Group([], {objectCaching: false});
      const priceGroup = new Group([], {objectCaching: false});

      for (const [cellType, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        const t = cells[a].getValue() as string;

        switch (cellType) {
          case CellType.NAME:
            nameAndIcons.insertAt(
              nameAndIcons.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.PRICE:
            priceGroup.insertAt(
              priceGroup.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.ICONS:
            for (let z = 0; z < t.length; z++) {
              const icon = await getIconSvg(t[z]);

              if (icon) {
                const scale = this.getScaleForMenuIcon(icon, this.item.iconsSize);
                icon.set({
                  scaleX: scale,
                  scaleY: scale,
                });
                nameAndIcons.insertAt(nameAndIcons.getObjects().length, icon);
              }
            }
            break;

          case CellType.VARIATION: {
            const val = cells[a].getValue() as VariationData[];
            if (val.length > 0) {
              this.emptyGroup(priceGroup);
              for (let i = 0; i < val.length; i++) {
                const g = [];
                g.push(
                  new IText(val[i].name, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                g.push(
                  new IText(val[i].price, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                priceGroup.insertAt(0, new Group(g, {objectCaching: false}));
              }
            }
            break;
          }
          default:
            break;
        }
      }

      const dottedText = new IText('.', {
        editable: false,
        objectCaching: textObjectCaching,
        ignoreDelegatedSet: true,
      });
      horizontalGroups.push(new Group([nameAndIcons, dottedText, priceGroup], {objectCaching: false}));
    }
    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(horizontalGroups);
  }

  /**
   * Sets the view styles specific to this layout
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const strokeWidth: number = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeColor = rgbaToHexString(this.item.textStyles.fill.fillColor[0]);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';
    let strokeWidthForFF1 = 0;
    let strokeWidthForFF2 = 0;

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 = !isBoldAppliedOnFirstFont && this.item.textStyles.isBold ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize + strokeWidth : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont && this.item.isBold2 ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeColor = rgbaToHexString(this.item.textStyles.strokeColor);
    }
    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects();
      const nameAndIcon = menuItems[0] as Group;
      const price = menuItems[2] as Group;

      nameAndIcon.getObjects()[0].set({
        fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
        fontSize: this.item.textStyles.fontSize,
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      });
      if (strokeWidthForFF1) {
        nameAndIcon.getObjects()[0].set({
          strokeWidth: strokeWidthForFF1,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
        });
      }

      const icons = nameAndIcon.getObjects();
      for (let j = 1; j < icons.length; j++) {
        icons[j].set({
          fill: rgbaToHexString([this.item.iconsColor[0], this.item.iconsColor[1], this.item.iconsColor[2], this.item.fabricObject.opacity]),
        });
      }

      applyToChildObjects(price, {
        fontFamily: `'${fontFamily2WithVariation}'`,
      });

      const priceObjects = price.getObjects() as Group[];
      for (let a = 0; a < priceObjects.length; a++) {
        if (priceObjects[a] instanceof Group) {
          this.horizontallyStackItems(priceObjects[a], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
          this.horizontallyCenterItems(priceObjects[a]);
          priceObjects[a].getObjects().forEach((obj) => {
            obj.set({
              fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
              underline: this.item.underLine2,
              linethrough: this.item.lineThrough2,
            });
            if (strokeWidthForFF2) {
              obj.set({
                strokeWidth: strokeWidthForFF2,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
              });
            }
          });
        } else {
          priceObjects[a].set({
            fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
            underline: this.item.underLine2,
            linethrough: this.item.lineThrough2,
          });
          if (strokeWidthForFF2) {
            priceObjects[a].set({
              strokeWidth: strokeWidthForFF2,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
            });
          }
        }
      }

      this.verticallyStackItems(nameAndIcon, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      this.verticallyCenterItems(nameAndIcon);
      this.verticallyStackItems(price, 10, 0);
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   */
  layoutItemsInsideGroups(xSpacing: number): void {
    const groups = this.item.fabricObject.getObjects() as Group[];

    for (let i = 0; i < groups.length; i++) {
      const newDim = this.getNewViewDimensions(groups[i], 'vertical');
      groups[i].set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
      });
      this.verticallyStackItems(groups[i], xSpacing, 0);
    }
  }
}
