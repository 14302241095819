import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import type {CellType, Cell} from '@PosterWhiteboard/items/layouts/cells/cell';
import {rgbaToHexString} from '@Utils/color.util';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {BOLD_STROKE_WIDTH_FACTOR, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText} from '@postermywall/fabricjs-2';
import {Layout} from './layout';

export class OffsetSportsLayout extends Layout {
  public layoutType = LayoutTypes.OFFSET_SPORTS_LAYOUT;

  public constructor(item: TableItem) {
    super(item);
    this.strokeWidth = 2;
  }

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.setStylesForHighlightedItems();
  }

  async setItems(): Promise<void> {
    const items = [];
    const columnMap = this.item.getColumnMap();
    const rows = this.item.getNoOfRows();

    for (let i = 0; i < rows; i++) {
      const textItems: IText[] = [];
      for (const [, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        textItems.push(new IText((cells[i].getValue() as string).toUpperCase(), {editable: false}));
      }
      items.push(new Group(textItems, {}));
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(items);
  }

  /**
   * Sets styles for highlighted items in the table/schedule
   * @override
   */
  setStylesForHighlightedItems(): void {
    const {highlightedRows} = this.item.fabricObject;
    const objects = this.item.fabricObject.getObjects();
    const color = rgbaToHexString(this.item.highlightedTextColor);

    if (highlightedRows.length > 0) {
      for (let i = 0; i < highlightedRows.length; i++) {
        const item = objects[highlightedRows[i]] as Group;
        const obs = item.getObjects();

        for (let x = 0; x < obs.length; x++) {
          obs[x].set({
            fill: color,
            stroke: this.item.textStyles.stroke ? rgbaToHexString(this.item.textStyles.strokeColor) : color,
          });
        }
      }
    }
  }

  /**
   * Sets the styles on items inside the view, specific to this layout.
   * @private
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    let strokeColor = rgbaToHexString(this.item.textStyles.fill.fillColor[0]);
    let strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForFF2;
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidth = !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * 1.5 * this.item.textStyles.strokeWidth;
      strokeWidth = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeColor = rgbaToHexString(this.item.textStyles.strokeColor);
    }
    for (let i = 0; i < groups.length; i++) {
      const groupItems = groups[i].getObjects();
      const stylesForFF1: Record<string, any> = {
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      };
      if (strokeWidth) {
        stylesForFF1.strokeWidth = strokeWidth;
        stylesForFF1.strokeLineJoin = 'round';
        stylesForFF1.paintFirst = paintFirst;
        stylesForFF1.stroke = strokeColor;
      }
      if (!isItalicAppliedOnFirstFont && this.item.textStyles.isItalic) {
        stylesForFF1.fontStyle = 'italic';
      }
      groupItems[1].set({
        fontSize: (groupItems[1] as IText).fontSize * 1.5,
        fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
        fontFamily: fontFamily2WithVariation,
        underline: this.item.underLine2,
        linethrough: this.item.lineThrough2,
      });
      if (strokeWidthForFF2) {
        groupItems[1].set({
          strokeWidth: strokeWidthForFF2 * 1.5,
          strokeLineJoin: 'round',
          paintFirst,
          stroke: strokeColor,
        });
      }
      groupItems[0].set(stylesForFF1);
      groupItems[2].set(stylesForFF1);
      groupItems[0].set({
        width: groupItems[0].width + (groupItems[0] as IText).fontSize * 1.5,
      });
      groupItems[2].set({
        width: groupItems[2].width + (groupItems[2] as IText).fontSize * 1.5,
      });
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   * @private
   */
  layoutItemsInsideGroups(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    for (let i = 0; i < groups.length; i++) {
      const newDim = this.getNewViewDimensions(groups[i], 'horizontal');
      groups[i].set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
      });

      const items = groups[i].getObjects() as IText[];
      this.horizontallyStackItems(groups[i], 0, 0);

      for (let j = 0; j < items.length; j++) {
        if (j !== 1) {
          items[j].set({
            left: items[j].left + items[j].fontSize,
          });
        }
      }
    }
  }
}
