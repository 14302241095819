import type {TableItem} from '@PosterWhiteboard/items/table-item/table-item.class';
import {rgbaToHexString} from '@Utils/color.util';
import {isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import type {Cell, TimeCellValue} from '@PosterWhiteboard/items/layouts/cells/cell';
import {CellType, isDateCellValue} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getFormattedDate} from '@Components/table/table-helper';
import {BOLD_STROKE_WIDTH_FACTOR, TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import type * as Fabric from '@postermywall/fabricjs-2';
import {TimeFormat} from '@Components/table/table.types';
import {Group, IText} from '@postermywall/fabricjs-2';
import {applyToChildObjects} from '@Utils/fabric.util';
import {LayoutTypes} from './layout.types';
import {Layout} from './layout';

export class SlantedSportsLayout extends Layout {
  public layoutType: LayoutTypes.SLANTED_SPORTS_LAYOUT | LayoutTypes.STRAIGHT_SPORTS_LAYOUT;

  public constructor(item: TableItem, isSlanted: boolean) {
    super(item);
    this.layoutType = isSlanted ? LayoutTypes.SLANTED_SPORTS_LAYOUT : LayoutTypes.STRAIGHT_SPORTS_LAYOUT;
  }

  async doLayout(): Promise<void> {
    this.setViewStyles();
    await this.layoutItemsInsideGroups();
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, 15);
    this.setStylesForHighlightedItems();
    if (this.layoutType === LayoutTypes.SLANTED_SPORTS_LAYOUT) {
      this.leanItemsInsideView();
    }
  }

  /**
   * Sets the styles on items inside the view, specific to this layout.
   * @private
   */
  setViewStyles(): void {
    applyToChildObjects(this.item.fabricObject, {
      lineHeight: (this.item.textStyles.leading - 30) / 113,
    });

    const groups = this.item.fabricObject.getObjects() as Fabric.Group[];
    let strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeColor = rgbaToHexString(this.item.textStyles.fill.fillColor[0]);
    let strokeWidthForDate = strokeWidth * 2;
    let strokeWidthForMonth = strokeWidth;
    const isBoldApplied = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicApplied = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForMonth += !isBoldApplied ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
      strokeWidthForDate += !isBoldApplied ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
      strokeWidth = !isBoldApplied ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    } else if (this.item.textStyles.stroke) {
      strokeWidth = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForMonth += this.item.textStyles.fontSize * 0.75 * TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.strokeWidth;
      strokeWidthForDate += this.item.textStyles.fontSize * 2 * TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.strokeWidth;
      strokeColor = rgbaToHexString(this.item.textStyles.strokeColor);
    }
    for (let i = 0; i < groups.length; i++) {
      const groupItems = groups[i].getObjects() as Fabric.Group[];
      for (let i2 = 0; i2 < groupItems.length; i2++) {
        const items = groupItems[i2].getObjects() as Fabric.IText[];
        for (let x = 0; x < items.length; x++) {
          items[x].set({
            fontStyle: !isItalicApplied && this.item.textStyles.isItalic ? 'italic' : 'normal',
            underline: this.item.textStyles.underLine,
            linethrough: this.item.textStyles.lineThrough,
          });
          switch (items[x].dataType as CellType) {
            case CellType.MONTH:
              items[x].set({
                fontSize: this.item.textStyles.fontSize * 0.75,
                strokeWidth: strokeWidthForMonth,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
              });
              break;
            case CellType.DATE:
              items[x].set({
                fontSize: this.item.textStyles.fontSize * 2,
                strokeWidth: strokeWidthForDate,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
              });
              break;
            case CellType.TEAM:
              items[x].set({
                fontSize: this.item.textStyles.fontSize * 1.25,
              });
              if (strokeWidth) {
                items[x].set({
                  strokeWidth: strokeWidth * 1.5,
                  strokeLineJoin: 'round',
                  paintFirst,
                  stroke: strokeColor,
                });
              }
              break;
            case CellType.VENUE:
              items[x].set({
                fontSize: this.item.textStyles.fontSize * 0.75,
              });
              if (strokeWidth) {
                items[x].set({
                  strokeWidth: strokeWidth * 1.25,
                  strokeLineJoin: 'round',
                  paintFirst,
                  stroke: strokeColor,
                });
              }
              break;

            default:
              console.error('Unknown Cell type');
          }
        }
      }
    }
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in view, specific to this layout.
   * @override
   */
  async setItems(): Promise<void> {
    const data = this.item.layoutDataMap;
    let textItems = [];
    let cellValue = 'add text';

    const w = 0;
    const v = 1.0;
    const viewRows: Fabric.Group[] = [];

    for (let i = 0; i < this.item.rows; i++) {
      textItems = [];
      const venueAndTeamGroup = [];
      for (const [cellType, cells] of Object.entries(data) as [CellType, Cell[]][]) {
        const cell = cells[i];

        if (cellType !== CellType.HIGHLIGHT) {
          cellValue = cell.getValue() as string;
          const date = cell.value;
          if (cell.type === CellType.DATE && isDateCellValue(date)) {
            const dateInfo = getFormattedDate('M d', parseInt(date.timestamp, 10)).split(' ');
            const dateBox = [];

            dateBox.push(
              new IText(dateInfo[1], {
                dataType: CellType.DATE,
                padding: w,
                _fontSizeMult: v,
                editable: false,
              })
            );
            dateBox.push(
              new IText(dateInfo[0].toUpperCase(), {
                dataType: CellType.MONTH,
                padding: w,
                _fontSizeMult: v,
                editable: false,
              })
            );
            textItems.push(new Group(dateBox, {}));
          } else if (cell.type === CellType.VENUE) {
            if (data.time) {
              const timeText = (data.time[i].value as TimeCellValue).timeFormat !== TimeFormat.DISABLE ? ` | ${data.time[i].getValue() as string}` : '';
              venueAndTeamGroup.push(
                new IText((cellValue + timeText).toUpperCase(), {
                  dataType: cell.type,
                  _fontSizeMult: v,
                  editable: false,
                })
              );
            }
          } else if (cell.type === CellType.TIME) {
            // do nothing as time and venue is treated as one item in this layout
          } else {
            venueAndTeamGroup.push(
              new IText(cellValue.toUpperCase(), {
                dataType: cell.type,
                _fontSizeMult: v,
                editable: false,
              })
            );
          }
        }
      }
      textItems.push(new Group(venueAndTeamGroup, {}));

      viewRows.push(new Group(textItems, {}));
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(viewRows);
  }

  /**
   * Sets styles for highlighted items in the table/schedule
   * @override
   */
  setStylesForHighlightedItems(): void {
    const {highlightedRows} = this.item.fabricObject;
    const objects = this.item.fabricObject.getObjects() as Fabric.Group[];
    let done = 0;
    const textColo1 = rgbaToHexString([this.item.highlightedBackgroundColor[0], this.item.highlightedBackgroundColor[1], this.item.highlightedBackgroundColor[2], 1]);
    const textColor2 = rgbaToHexString(this.item.highlightedTextColor);
    const backgroundColor1 = rgbaToHexString([
      this.item.highlightedTextColor[0],
      this.item.highlightedTextColor[1],
      this.item.highlightedTextColor[2],
      this.item.highlightedBackgroundColor[3],
    ]);
    const backgroundColor2 = rgbaToHexString(this.item.highlightedBackgroundColor);

    for (const [index, object] of objects.entries()) {
      const itemsInside = object.getObjects() as Fabric.Group[];
      const textItems = itemsInside[0].getObjects() as Fabric.IText[];
      let backGroundColor;
      let textColor;

      if (highlightedRows.includes(index, done)) {
        textColor = textColo1;
        backGroundColor = backgroundColor1;
        done += 1;
      } else {
        backGroundColor = backgroundColor2;
        textColor = textColor2;
      }

      itemsInside[0].set({
        leanBackground: this.layoutType === LayoutTypes.SLANTED_SPORTS_LAYOUT,
        leanBackgroundOffset: this.item.textStyles.fontSize,
        backgroundColor: backGroundColor,
      });
      for (let x = 0; x < textItems.length; x++) {
        textItems[x].set({
          fill: textColor,
          stroke: this.item.textStyles.stroke ? rgbaToHexString(this.item.textStyles.strokeColor) : textColor,
        });
      }
    }
  }

  /**
   * Position the items inside the groups and reset each group dimensions.
   */
  async layoutItemsInsideGroups(): Promise<void> {
    const groups = this.item.fabricObject.getObjects() as Fabric.Group[];
    const promises = [];
    for (let i = 0; i < groups.length; i++) {
      const items = groups[i].getObjects() as Fabric.Group[];
      this.horizontallyStackItems(items[0], this.item.textStyles.fontSize * 0.5, this.item.textStyles.fontSize * 0.6);
      this.horizontallyCenterItems(items[0]);
      this.horizontallyStackItems(items[1], this.item.textStyles.fontSize * 0.5, 0);
      promises.push(items[0].clone());
    }
    const dateItems = await Promise.all(promises);

    const boxDimensions = this.getNewViewDimensions(new Group(dateItems, {}), 'horizontal');

    for (let i = 0; i < groups.length; i++) {
      groups[i].getObjects()[0].set({
        width: boxDimensions.width,
      });
      this.verticallyStackItems(groups[i], this.item.xSpacing, 0);
      this.verticallyCenterItems(groups[i]);
    }
  }

  /**
   * Layout items inside the view so that the view looks slanted
   * @private
   */
  leanItemsInsideView(): void {
    const objects = this.item.fabricObject.getObjects();
    const newViewDim = this.item.fabricObject.width + (this.item.textStyles.fontSize / 2) * (objects.length - 1) + 2 * this.edgePadding;

    this.item.fabricObject.set({
      width: newViewDim,
    });

    for (let i = objects.length - 1; i >= 0; i--) {
      if (i === objects.length - 1) {
        objects[i].set({
          left: -this.item.fabricObject.width / 2 + this.edgePadding + this.item.textStyles.fontSize / 2,
        });
      } else {
        objects[i].set({
          left: objects[i + 1].left + this.item.textStyles.fontSize / 2,
        });
      }
    }
  }
}
