import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject, InitItemOpts} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {RGBA} from '@Utils/color.util';
import {rgbaToHexString} from '@Utils/color.util';
import type {Cell, CellObject, TimeCellValue} from '@PosterWhiteboard/items/layouts/cells/cell';
import {CellType, createCellFromObject} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getColumnCountForLayout, getColumnsForLayout, isSecondFontFamilyUsed, getLayoutHelperClass} from '@PosterWhiteboard/items/layouts/layout.library';
import {getBackgroundColor} from '@PosterWhiteboard/libraries/text.library';
import editIconSVG from '@PosterWhiteboard/poster/poster-item-control-svgs/edit-icon.svg';
import {addFonts, getFontFamilyNameForVariations} from '@Libraries/font-library';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {TextStylesObject} from '@PosterWhiteboard/classes/text-styles.class';
import {DEFAULT_STROKE_WIDTH, TEXT_OUTLINE_STROKE_WIDTH_FACTOR, TextStyles} from '@PosterWhiteboard/classes/text-styles.class';
import {TimeFormat} from '@Components/table/table.types';
import {openEditScheduleModal, openEditTableContentModal, openEditTableModal} from '@Components/poster-editor/library/poster-editor-open-modals';
import {invalidateTableDataForCustomTableLayout, invalidateTableDataForFixedLayout, invalidateUnusedData} from '@PosterWhiteboard/items/table-item/user-table';
import type {TableData} from '@Libraries/add-media-library';
import {ElementDataType} from '@Libraries/add-media-library';
import type {ScheduleData} from '@Panels/user-schedule-panel/user-schedule-panel.types';
import type {LayoutType} from '@PosterWhiteboard/items/layouts/layout.types';
import {LayoutBackgroundTypes, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import type {UserTableProps} from '@Panels/user-table-panel/user-table-panel';
import type {ColumnsDataMap, LayoutDataMap, LayoutDataMapObject} from '@PosterWhiteboard/items/table-item/table-item.types';
import {BorderType} from '@PosterWhiteboard/classes/item-border.class';
import type {FabricObject, TableProps} from '@postermywall/fabricjs-2';
import {FixedLayout, LayoutManager, Group, IText, Table} from '@postermywall/fabricjs-2';
import {applyToChildObjects} from '@Utils/fabric.util';
import type {DeepPartial} from '@/global';
import type {
  CopyableItemStylesAndProperties,
  TableItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForTableItem} from '@PosterWhiteboard/libraries/paste-styles.library';

export const TABLE_LAYOUTS = [
  LayoutTypes.SLANTED_SPORTS_LAYOUT,
  LayoutTypes.STRAIGHT_SPORTS_LAYOUT,
  LayoutTypes.OFFSET_SPORTS_LAYOUT,
  LayoutTypes.SPORTS_LAYOUT,
  LayoutTypes.BAND_LAYOUT_LEFT_ALIGN,
  LayoutTypes.BAND_LAYOUT_RIGHT_ALIGN,
  LayoutTypes.BAND_LAYOUT_CENTER_ALIGN,
  LayoutTypes.TABLE_LAYOUT,
];
export const SCHEDULE_LAYOUTS_WITH_DATE_OPTIONS = [LayoutTypes.STRAIGHT_SPORTS_LAYOUT, LayoutTypes.SLANTED_SPORTS_LAYOUT];

export interface TableItemObject extends BaseItemObject {
  rows: number;
  columns: number;
  layoutDataMap: LayoutDataMapObject;
  unusedData: Record<string, CellObject[]>;
  layoutStyle: string;
  alternateBackgroundColor1: RGBA;
  alternateBackgroundColor2: RGBA;
  highlightedBackgroundColor: RGBA;
  highlightedTextColor: RGBA;
  xSpacing: number;
  ySpacing: number;
  fontFamily2: string;
  isBold2: boolean;
  isItalic2: boolean;
  underLine2: boolean;
  lineThrough2: boolean;
  textStyles: TextStylesObject;
  backgroundType: number;
  backgroundColor: RGBA;
}

interface HighlightedRowsData {
  color: string;
  rows: number[];
}

export class TableItem extends Item {
  declare fabricObject: Table;

  public gitype = ITEM_TYPE.TABLE;
  public rows = 0;
  public columns = 0;
  public fontSize = 0;
  public layoutDataMap: LayoutDataMap = {};
  public unusedData: Record<string, Cell[]> = {};
  public layoutStyle: LayoutTypes = LayoutTypes.TABLE_LAYOUT;
  public alternateBackgroundColor1: RGBA = [180, 180, 180, 0.5];
  public alternateBackgroundColor2: RGBA = [0, 0, 0, 0.5];
  public highlightedBackgroundColor: RGBA = [180, 180, 180, 1];
  public highlightedTextColor: RGBA = [75, 75, 75, 1];
  public xSpacing = 0;
  public ySpacing = 0;
  public fontFamily2 = '';
  public isBold2 = false;
  public isItalic2 = false;
  public underLine2 = false;
  public lineThrough2 = false;
  public backgroundType: LayoutBackgroundTypes = LayoutBackgroundTypes.NONE;
  public backgroundColor: RGBA = [184, 184, 184, 1];
  public layout!: LayoutType;
  public textStyles: TextStyles;

  constructor(page: Page) {
    super(page);
    this.textStyles = new TextStyles();
  }

  protected override async onBeforeItemInitialize(): Promise<void> {
    await this.loadFonts();
  }

  protected override async onItemInitialized(): Promise<void> {
    await this.refreshView();
    this.updateItemDimensions();
  }

  protected onItemDoubleClicked(): void {
    this.editItem();
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      layoutStyle: this.layoutStyle,
      alternateBackgroundColor1: this.alternateBackgroundColor1,
      alternateBackgroundColor2: this.alternateBackgroundColor2,
      highlightedBackgroundColor: this.highlightedBackgroundColor,
      highlightedTextColor: this.highlightedTextColor,
      xSpacing: this.xSpacing,
      ySpacing: this.ySpacing,
      fontFamily2: this.fontFamily2,
      isBold2: this.isBold2,
      isItalic2: this.isItalic2,
      underLine2: this.underLine2,
      lineThrough2: this.lineThrough2,
      backgroundType: this.backgroundType,
      backgroundColor: this.backgroundColor,
      textStyles: this.textStyles.toObject(),
    } as TableItemStyles;
  }

  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForTableItem(copiedProperties, this);
  }

  public getColumnMap(): ColumnsDataMap {
    const {highlight, ...columnsMap} = this.layoutDataMap;
    return columnsMap;
  }

  public getNumberOfEnabledColumns(): number {
    return Object.keys(this.getEnabledColumnMap()).length;
  }

  public getEnabledColumnMap(): ColumnsDataMap {
    const enabledColumnMap: ColumnsDataMap = {};
    for (const [columnType, columnCells] of Object.entries(this.getColumnMap()) as [CellType, Cell[]][]) {
      if (this.isColumnEnabled(columnType, columnCells)) {
        enabledColumnMap[columnType] = columnCells;
      }
    }
    return enabledColumnMap;
  }

  private isColumnEnabled(columnType: CellType, columnCells: Cell[]): boolean {
    return !(columnType === CellType.TIME && (columnCells[0].value as TimeCellValue).timeFormat === TimeFormat.DISABLE);
  }

  public getNoOfRows(): number {
    const columnMap = this.getColumnMap();
    const firstColumnKey = Object.keys(columnMap)[0] as CellType;
    const cells = columnMap[firstColumnKey];

    return cells ? cells.length : 0;
  }

  public editItem(): void {
    if (this.isCustomTableLayout()) {
      openEditTableModal(this);
    } else {
      openEditScheduleModal(this);
    }
  }

  protected async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    await this.loadFonts();
    await this.refreshView();
  }

  protected async getFabricObjectForItem(opts: InitItemOpts = {}): Promise<Table> {
    return new Promise((resolve) => {
      resolve(
        new Table([], {
          perPixelTargetFind: true,
          pmwBmBtnText: window.i18next.t('pmwjs_edit_item'),
          pmwBmBtnIcon: editIconSVG as string,
          scaleX: opts?.scaleX ?? 1,
          scaleY: opts?.scaleY ?? 1,
          layoutManager: new LayoutManager(new FixedLayout()),
        })
      );
    });
  }

  protected async loadFonts(): Promise<void> {
    return new Promise((resolve, reject) => {
      addFonts(
        this.getFonts(true),
        () => {
          resolve();
        },
        reject
      );
    });
  }

  public toObject(): TableItemObject {
    const layoutDataObject: Record<string, CellObject[]> = {};
    const unusedDataObject: Record<string, CellObject[]> = {};

    for (const [columnType, columnCells] of Object.entries(this.layoutDataMap)) {
      layoutDataObject[columnType] = [];
      for (const cell of columnCells) {
        layoutDataObject[columnType].push(cell.toObject());
      }
    }

    if (Object.keys(this.unusedData).length) {
      for (const [columnType, columnCells] of Object.entries(this.unusedData)) {
        unusedDataObject[columnType] = [];
        for (const cell of columnCells) {
          unusedDataObject[columnType].push(cell.toObject());
        }
      }
    }

    return {
      ...super.toObject(),
      textStyles: this.textStyles.toObject(),
      rows: this.rows,
      columns: this.columns,
      layoutDataMap: layoutDataObject,
      unusedData: unusedDataObject,
      layoutStyle: this.layoutStyle,
      alternateBackgroundColor1: this.alternateBackgroundColor1,
      alternateBackgroundColor2: this.alternateBackgroundColor2,
      highlightedBackgroundColor: this.highlightedBackgroundColor,
      highlightedTextColor: this.highlightedTextColor,
      xSpacing: this.xSpacing,
      ySpacing: this.ySpacing,
      fontFamily2: this.fontFamily2,
      isBold2: this.isBold2,
      isItalic2: this.isItalic2,
      underLine2: this.underLine2,
      lineThrough2: this.lineThrough2,
      backgroundType: this.backgroundType,
      backgroundColor: this.backgroundColor,
    };
  }

  public updateFromTableData(data: TableData): void {
    void this.updateFromObject({
      layoutDataMap: invalidateTableDataForCustomTableLayout(data),
      columns: data.columns,
      rows: data.rows,
    });
  }

  public updateFromScheduleData(data: ScheduleData): void {
    void this.updateFromObject({
      layoutDataMap: invalidateTableDataForFixedLayout(data),
      unusedData: invalidateUnusedData(data),
      columns: data.columns,
      rows: data.rows,
      layoutStyle: data.layoutStyle,
    });
  }

  public updateTableLayout(layoutStyle: LayoutTypes): void {
    void this.updateFromObject(this.getChangesOnLayoutChange(layoutStyle));
  }

  public getChangesOnLayoutChange(layoutStyle: LayoutTypes): Partial<TableItemObject> {
    const updatedData = this.getDataForLayout(layoutStyle);
    const changes: Partial<TableItemObject> = {
      columns: getColumnCountForLayout(layoutStyle),
      layoutStyle,
      layoutDataMap: updatedData.layoutDataMap,
      unusedData: updatedData.unusedData,
    };

    if (layoutStyle !== LayoutTypes.TABLE_LAYOUT && this.hasAlternateColorBackground()) {
      changes.backgroundType = LayoutBackgroundTypes.COLOR;
    }

    return changes;
  }

  public getDataForLayout(layoutStyle: LayoutTypes): Partial<TableItemObject> {
    const layoutColumns = getColumnsForLayout(layoutStyle);
    const tableData: Record<string, any> = {};
    const availableData = {
      ...this.unusedData,
      ...this.layoutDataMap,
    };
    const availableDataObject: Record<string, any> = {};

    for (const [key, value] of Object.entries(availableData)) {
      availableDataObject[key] = value.map((CellItem) => {
        return CellItem.toObject();
      });
    }

    const props = Object.keys(layoutColumns);

    props.forEach((prop) => {
      tableData[prop] = availableDataObject[prop] as CellObject;
      delete availableDataObject[prop];
    });

    return {
      layoutDataMap: tableData,
      unusedData: availableDataObject,
    };
  }

  public copyVals(obj: DeepPartial<TableItemObject>): void {
    const {textStyles, layoutDataMap, unusedData, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.textStyles.copyVals(textStyles);

    if (layoutDataMap) {
      // layoutDataMap as Array<string, CellObject>;
      this.layoutDataMap = {};
      for (const [columnType, columnCells] of Object.entries(layoutDataMap) as [CellType, CellObject[]][]) {
        this.layoutDataMap[columnType] = [];
        if (columnCells) {
          for (const cell of columnCells) {
            this.layoutDataMap[columnType].push(createCellFromObject(cell));
          }
        }
      }
    }

    if (unusedData) {
      this.unusedData = {};
      for (const [columnType, columnCells] of Object.entries(unusedData)) {
        this.unusedData[columnType] = [];
        if (columnCells) {
          for (const cell of columnCells) {
            this.unusedData[columnType].push(createCellFromObject(cell));
          }
        }
      }
    }
  }

  async refreshView(): Promise<void> {
    // set layout and insert table data in view
    if (this.layout === undefined || this.layoutStyle !== this.layout.layoutType) {
      this.layout = getLayoutHelperClass(this, this.layoutStyle);
    }
    await this.layout.setItems();
    const options: Partial<TableProps> = {};
    options.fontSize = this.textStyles.fontSize;
    // options.lockUniScaling = true;
    options.columns = TableItem.getColumnsForTable(this);
    options.rows = this.rows;
    options.layoutType = this.layout.layoutType;
    options.backgroundColor = getBackgroundColor(this);

    if (this.backgroundType === LayoutBackgroundTypes.ALTERNATE) {
      options.alternateBackgroundColor1 = rgbaToHexString(this.alternateBackgroundColor1);
      options.alternateBackgroundColor2 = rgbaToHexString(this.alternateBackgroundColor2);
    } else {
      options.alternateBackgroundColor1 = '';
      options.alternateBackgroundColor2 = '';
    }

    // TODO: Move these to schedule
    const highlightedRowsOptions = this.getHighlightedRowsData();
    options.highlightedRowsBackgroundColor = highlightedRowsOptions.color;
    options.highlightedRows = highlightedRowsOptions.rows;

    options.strokeWidth = 0;
    options.strokeLineJoin = 'miter';
    if (this.border.hasBorder()) {
      options.strokeWidth = this.border.solidBorderThickness;
      options.stroke = rgbaToHexString(this.border.solidBorderColor);

      if (this.border.solidBorderType === BorderType.ROUNDED_BORDER) {
        options.strokeLineJoin = 'round';
      }
    } else {
      options.strokeWidth = 0;
    }
    this.fabricObject.set(options);
    applyToChildObjects(this.fabricObject, {
      ...this.textStyles.getTextStyles(this.fabricObject.width, this.fabricObject.height),
    });
    this.applyFontVariationOnTable(this.fabricObject);
    this.applyTextStrokeOnTable(this.fabricObject);
    await this.layout.doLayout();
    this.fabricObject.setCoords();
  }

  protected applyBorder(): void {}

  public isCustomTableLayout(): boolean {
    return this.layoutStyle === LayoutTypes.CUSTOM_TABLE_LAYOUT;
  }

  /**
   * Returns the data/styles to use for styling the rows which are highlighted by the user.
   * @returns {{color: (String), rows: Array}}
   * @private
   */
  public getHighlightedRowsData(): HighlightedRowsData {
    const options: HighlightedRowsData = {
      color: rgbaToHexString(this.highlightedBackgroundColor),
      rows: [],
    };

    const highlightedRowsData = this.layoutDataMap.highlight;
    if (highlightedRowsData) {
      for (let i = 0; i < highlightedRowsData.length; i++) {
        const val = highlightedRowsData[i].getValue();
        if (val) {
          options.rows.push(i);
        }
      }
    }
    return options;
  }

  /**
   * apply fabric styles on table column/row text
   */
  public applyFontVariationOnTable(fabricItem: Table | Group | IText | FabricObject): void {
    if ([LayoutTypes.CUSTOM_TABLE_LAYOUT, LayoutTypes.TABLE_LAYOUT].includes(this.layout.layoutType)) {
      if (fabricItem instanceof Table || fabricItem instanceof Group) {
        const items = fabricItem.getObjects();
        for (let i = 0; i < items.length; i++) {
          this.applyFontVariationOnTable(items[i]);
        }
      } else if (fabricItem instanceof IText) {
        fabricItem.set(this.textStyles.getTextStyles(fabricItem.width, fabricItem.height));
        this.applyTextEmphasisStylesOnTable(fabricItem);
      }
    }
  }

  /**
   * apply fabric underline/linethrough style on table column/row text
   */
  public applyTextEmphasisStylesOnTable(tab: IText): void {
    tab.set({underline: this.textStyles.underLine});
    tab.set({linethrough: this.textStyles.lineThrough});
  }

  /**
   * apply text stroke on table column/row text
   */
  public applyTextStrokeOnTable(item: Table | Group | IText | FabricObject): void {
    if ([LayoutTypes.CUSTOM_TABLE_LAYOUT, LayoutTypes.TABLE_LAYOUT].includes(this.layout.layoutType)) {
      if (item instanceof Table || item instanceof Group) {
        const items = item.getObjects();
        for (let i = 0; i < items.length; i++) {
          this.applyTextStrokeOnTable(items[i]);
        }
      } else if (item instanceof IText) {
        if (this.textStyles.stroke) {
          item.set({
            stroke: rgbaToHexString(this.textStyles.strokeColor),
            strokeLineJoin: 'round',
            paintFirst: 'stroke',
            strokeWidth: item.fontSize * this.textStyles.strokeWidth * TEXT_OUTLINE_STROKE_WIDTH_FACTOR,
          });
        } else if (!this.textStyles.isBold) {
          item.set({
            stroke: null,
            strokeLineJoin: 'miter',
            paintFirst: 'fill',
            strokeWidth: DEFAULT_STROKE_WIDTH,
          });
        }
      }
    }
  }

  public getFonts(withVariation: boolean): string[] {
    const fontFamilies = [
      withVariation ? getFontFamilyNameForVariations(this.textStyles.fontFamily, this.textStyles.isBold, this.textStyles.isItalic) : this.textStyles.fontFamily,
    ];

    if (isSecondFontFamilyUsed(this.layoutStyle)) {
      if (this.fontFamily2 !== '') {
        fontFamilies[fontFamilies.length] = withVariation ? getFontFamilyNameForVariations(this.fontFamily2, this.isBold2, this.isItalic2) : this.fontFamily2;
      }
    }
    return fontFamilies;
  }

  public hasAlternateColorBackground(): boolean {
    return this.backgroundType === LayoutBackgroundTypes.ALTERNATE;
  }

  public static getColumnsForTable(item: TableItem): number {
    return !item.isCustomTableLayout() && CellType.TIME in item.layoutDataMap && TableItem.isTimeColumnDisabled(item) ? item.columns - 1 : item.columns;
  }

  public static isTimeColumnDisabled(item: TableItem): boolean {
    const timeCells = item.layoutDataMap[CellType.TIME];
    if (timeCells) {
      const timeCellValue = timeCells[0].value as TimeCellValue;
      return timeCellValue.timeFormat === TimeFormat.DISABLE;
    }
    return false;
  }

  public getColors(): RGBA[] {
    let colors = super.getColors();

    if (this.textStyles.fill.hasFill()) {
      colors = [...colors, ...this.textStyles.fill.fillColor];
    }

    if (this.backgroundType === LayoutBackgroundTypes.COLOR) {
      colors.push(this.backgroundColor);
    }
    return colors;
  }

  public doesItemHaveDateOptions(): boolean {
    return SCHEDULE_LAYOUTS_WITH_DATE_OPTIONS.includes(this.layoutStyle);
  }

  public updateHighlightedTextColor(highlightedTextColor: RGBA, undoable = true): void {
    void this.updateFromObject(
      {
        highlightedTextColor,
      },
      {
        undoable,
      }
    );
  }

  public updateHighlightedBackgroundColor(highlightedBackgroundColor: RGBA, undoable = true): void {
    void this.updateFromObject(
      {
        highlightedBackgroundColor,
      },
      {
        undoable,
      }
    );
  }

  public toggleBold2(): void {
    void this.updateFromObject({
      isBold2: !this.isBold2,
    });
  }

  public toggleItalic2(): void {
    void this.updateFromObject({
      isItalic2: !this.isItalic2,
    });
  }

  public toggleUnderline2(): void {
    void this.updateFromObject({
      underLine2: !this.underLine2,
    });
  }

  public toggleLineThrough2(): void {
    void this.updateFromObject({
      lineThrough2: !this.lineThrough2,
    });
  }

  protected onItemDoubleTapped(): void {
    openEditTableContentModal(this);
  }
}

export const addTableToPoster = (tableData: UserTableProps): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addTableItem(
    {
      type: ElementDataType.TABLE,
      ...tableData,
    } as TableData,
    {},
    true
  );
};

export const addScheduleToPoster = (scheduleData: ScheduleData): void => {
  const currentPage = window.posterEditor?.whiteboard?.getCurrentPage();
  if (!currentPage) {
    return;
  }
  void currentPage.items.addItems.addScheduleItem(
    {
      ...scheduleData,
      type: ElementDataType.TABLE,
    },
    {},
    true
  );
};
