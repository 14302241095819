import type {MenuItem} from '@PosterWhiteboard/items/menu-item/menu-item.class';
import {rgbaToHexString, rgbToHexString} from '@Utils/color.util';
import {getFontFamilyNameForVariations, isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {type Cell, CellType, type VariationData} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getIconSvg} from '@PosterWhiteboard/items/menu-item/menu-icons';
import {MenuLayout3} from '@PosterWhiteboard/items/layouts/menu-layout-3';
import {BOLD_STROKE_WIDTH_FACTOR, DEFAULT_SPACING_BETWEEN_ITEMS, LayoutTypes} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText, Textbox} from '@postermywall/fabricjs-2';
import {applyToChildObjects} from '@Utils/fabric.util';

export class MenuLayout4 extends MenuLayout3 {
  public layoutType: LayoutTypes.MENU_LAYOUT_4 = LayoutTypes.MENU_LAYOUT_4;
  declare item: MenuItem;

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.setExtraStyles();
    this.layoutItemsInsideGroups(this.edgePadding * 2);
    this.horizontallyStackItems(this.item.fabricObject, this.item.ySpacing, this.edgePadding);
    this.item.fabricObject.set({
      ySpacing: this.item.ySpacing,
      xSpacing: this.item.xSpacing,
    });
  }

  /**
   * Sets the this.item.fabricObject styles specific to this layout
   */
  setViewStyles(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    const strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForFF1 = 0;
    let strokeWidthForFF2 = 0;
    let strokeColor = rgbaToHexString(this.item.textStyles.fill.fillColor[0]);
    const isBoldAppliedOnFirstFont = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicAppliedOnFirstFont = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isBoldAppliedOnSecondFont = isBoldVariationAvaliableForFont(this.item.fontFamily2);
    const isItalicAppliedOnSecondFont = isItalicVariationAvaliableForFont(this.item.fontFamily2);
    const fontFamily2WithVariation = getFontFamilyNameForVariations(this.item.fontFamily2, this.item.isBold2, this.item.isItalic2);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';

    if (this.item.textStyles.isBold) {
      strokeWidthForFF1 = !isBoldAppliedOnFirstFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.isBold2) {
      strokeWidthForFF2 = !isBoldAppliedOnSecondFont ? BOLD_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize : 0;
    }
    if (this.item.textStyles.stroke) {
      strokeWidthForFF2 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeWidthForFF1 = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.fontSize * this.item.textStyles.strokeWidth;
      strokeColor = rgbaToHexString(this.item.textStyles.strokeColor);
    }

    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects() as Group[];
      const nameAndDescription = menuItems[0];
      const description = nameAndDescription.getObjects()[1];
      const nameAndIcon = menuItems[0].getObjects()[0] as Group;
      const price = menuItems[1];

      nameAndIcon.getObjects()[0].set({
        fontSize: this.item.textStyles.fontSize * 1.3,
        strokeWidth: strokeWidthForFF1 ? strokeWidthForFF1 * 1.3 + strokeWidth : strokeWidth,
        strokeLineJoin: 'round',
        paintFirst,
        stroke: strokeColor,
        fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
        underline: this.item.textStyles.underLine,
        linethrough: this.item.textStyles.lineThrough,
      });

      const icons = nameAndIcon.getObjects();
      for (let j = 1; j < icons.length; j++) {
        icons[j].set({
          fill: rgbaToHexString([this.item.iconsColor[0], this.item.iconsColor[1], this.item.iconsColor[2], this.item.fabricObject.opacity]),
        });
      }

      if (description) {
        description.set({
          fontStyle: !isItalicAppliedOnFirstFont && this.item.textStyles.isItalic ? 'italic' : 'normal',
          underline: this.item.textStyles.underLine,
          linethrough: this.item.textStyles.lineThrough,
        });
        if (strokeWidthForFF1) {
          description.set({
            strokeWidth: strokeWidthForFF1,
            strokeLineJoin: 'round',
            paintFirst,
            stroke: strokeColor,
          });
        }
      }

      applyToChildObjects(price, {
        fontFamily: `'${fontFamily2WithVariation}'`,
        fontSize: this.item.textStyles.fontSize * 1.2,
      });

      const priceObjects = price.getObjects() as Group[];
      for (let a = 0; a < priceObjects.length; a++) {
        if (priceObjects[a] instanceof Group) {
          this.horizontallyStackItems(priceObjects[a], DEFAULT_SPACING_BETWEEN_ITEMS, 0);
          this.horizontallyCenterItems(priceObjects[a]);
          priceObjects[a].getObjects().forEach((obj) => {
            obj.set({
              fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
              underline: this.item.underLine2,
              linethrough: this.item.lineThrough2,
            });
            if (strokeWidthForFF2) {
              obj.set({
                strokeWidth: strokeWidthForFF2,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
              });
            }
          });
        } else {
          priceObjects[a].set({
            fontStyle: !isItalicAppliedOnSecondFont && this.item.isItalic2 ? 'italic' : 'normal',
            underline: this.item.underLine2,
            linethrough: this.item.lineThrough2,
          });
          if (strokeWidthForFF2) {
            priceObjects[a].set({
              strokeWidth: strokeWidthForFF2,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
            });
          }
        }
      }

      this.verticallyStackItems(nameAndIcon, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      this.verticallyCenterItems(nameAndIcon);
      this.verticallyStackItems(price, DEFAULT_SPACING_BETWEEN_ITEMS * 2, 0);
      this.horizontallyStackItems(nameAndDescription, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
    }
  }

  /**
   * Function of parent class, overridden in child class for insertion of data in this.item.fabricObject, specific to this layout.
   */
  async setItems(): Promise<void> {
    const textObjectCaching = !this.item.page.poster.isHighRes;
    const columnMap = this.item.getColumnMap();
    const horizontalGroups: Group[] = [];
    const rows = this.item.getNoOfRows();

    for (let a = 0; a < rows; a++) {
      const nameAndDescription = new Group([], {objectCaching: false});
      const nameAndIcons = new Group([], {objectCaching: false});
      const priceGroup = new Group([], {objectCaching: false});

      for (const [cellType, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        const t = cells[a].getValue() as string;

        switch (cellType) {
          case CellType.NAME:
            nameAndIcons.insertAt(
              nameAndIcons.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.DESCRIPTION:
            if (t.length > 0) {
              /*
               * At highres, we don't let the Textbox determine text wrapping. Instead, we specify wrapping ourselves that matches
               * what the user saw in their browser when the poster was saved
               */
              let tItem;
              if (this.item.page.poster.isHighRes) {
                tItem = new IText(this.getWrapping(this.item, a, t), {
                  editable: false,
                  objectCaching: textObjectCaching,
                });
              } else {
                tItem = new Textbox(t, {
                  editable: false,
                  objectCaching: textObjectCaching,
                });
              }
              nameAndDescription.insertAt(nameAndDescription.getObjects().length, tItem);
            }
            break;

          case CellType.PRICE:
            priceGroup.insertAt(
              priceGroup.getObjects().length,
              new IText(t, {
                editable: false,
                objectCaching: textObjectCaching,
              })
            );
            break;

          case CellType.ICONS:
            for (let z = 0; z < t.length; z++) {
              const icon = await getIconSvg(t[z]);
              if (icon) {
                const scale = this.getScaleForMenuIcon(icon, this.item.iconsSize);
                icon.set({
                  scaleX: scale,
                  scaleY: scale,
                });
                nameAndIcons.insertAt(nameAndIcons.getObjects().length, icon);
              }
            }
            break;

          case CellType.VARIATION: {
            const cellVal = cells[a].getValue() as VariationData[];
            if (cellVal.length > 0) {
              this.emptyGroup(priceGroup);
              for (let i = 0; i < cellVal.length; i++) {
                const g = [];
                g.push(
                  new IText(cellVal[i].name, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                g.push(
                  new IText(cellVal[i].price, {
                    editable: false,
                    objectCaching: textObjectCaching,
                  })
                );
                priceGroup.insertAt(0, new Group(g, {objectCaching: false}));
              }
            }
            break;
          }

          default:
            break;
        }
      }

      nameAndDescription.insertAt(0, nameAndIcons);

      // add final group to the this.item.fabricObject
      horizontalGroups.push(
        new Group([nameAndDescription, priceGroup], {
          backgroundColor: rgbToHexString([250, 25, 100], 0),
          objectCaching: false,
        })
      );
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(horizontalGroups);
  }

  /**
   * This function resets the width of nameAndDescription items and then re-layout the items
   */
  setExtraStyles(): void {
    let price;
    let nameAndDescription;
    let menuItems;
    let i;
    const groups = this.item.fabricObject.getObjects() as Group[];
    let maxWidth = 0;
    let maxPriceWidth = 0;

    for (i = 0; i < groups.length; i++) {
      menuItems = groups[i].getObjects() as Group[];
      nameAndDescription = menuItems[0];
      price = menuItems[1];

      if (nameAndDescription.width > maxWidth) {
        maxWidth = nameAndDescription.width;
      }
      if (price.width > maxPriceWidth) {
        maxPriceWidth = price.width;
      }
    }

    if (this.item.page.poster.isHighRes) {
      maxWidth += this.item.fabricObject.width - maxWidth - maxPriceWidth - this.edgePadding * 4;
    } else {
      maxWidth += this.item.xSpacing;
    }

    for (i = 0; i < groups.length; i++) {
      menuItems = groups[i].getObjects() as Group[];
      nameAndDescription = menuItems[0];
      price = menuItems[1];

      const description = nameAndDescription.getObjects()[1];

      if (description) {
        description.set({
          width: maxWidth,
        });
      }

      this.horizontallyStackItems(nameAndDescription, DEFAULT_SPACING_BETWEEN_ITEMS, 0);
      nameAndDescription.set({
        width: maxWidth,
      });
      this.doLeftAlign(nameAndDescription);
    }
  }

  getWrappingInfo(): string[][] {
    const groups = this.item.fabricObject.getObjects() as Group[];
    const wrappingData = [];

    for (let i = 0; i < groups.length; i++) {
      const menuItems = groups[i].getObjects() as Group[];
      const description = menuItems[0].getObjects()[1];

      if (description) {
        if (description instanceof Textbox) {
          wrappingData.push(description.textLines);
        } else {
          wrappingData.push('');
        }
      } else {
        wrappingData.push('');
      }
    }
    return wrappingData as string[][];
  }
}
