import type {Cell, DateCellValue} from '@PosterWhiteboard/items/layouts/cells/cell';
import {CellType} from '@PosterWhiteboard/items/layouts/cells/cell';
import {getFormattedDate} from '@Components/table/table-helper';
import {rgbaToHexString} from '@Utils/color.util';
import {isBoldVariationAvaliableForFont, isItalicVariationAvaliableForFont} from '@Libraries/font-library';
import {TEXT_OUTLINE_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/classes/text-styles.class';
import {LayoutTypes, BOLD_STROKE_WIDTH_FACTOR} from '@PosterWhiteboard/items/layouts/layout.types';
import {Group, IText} from '@postermywall/fabricjs-2';
import {applyToChildObjects} from '@Utils/fabric.util';
import {Layout} from './layout';

export class SportsLayout extends Layout {
  public layoutType: LayoutTypes.SPORTS_LAYOUT = LayoutTypes.SPORTS_LAYOUT;

  async doLayout(): Promise<void> {
    this.setViewStyles();
    this.layoutItemsInsideGroups();
    this.verticallyStackItems(this.item.fabricObject, this.item.xSpacing, this.edgePadding);
    this.setStylesForHighlightedItems();
  }

  async setItems(): Promise<void> {
    const w = 0;
    const v = 1.0;
    const columnMap = this.item.getColumnMap();
    const rows = this.item.getNoOfRows();
    const fabricRowItems: Group[] = [];

    for (let i = 0; i < rows; i++) {
      const textItemsGroup = new Group([], {});
      for (const [, cells] of Object.entries(columnMap) as [CellType, Cell[]][]) {
        if (cells[i].type === CellType.DATE) {
          const date = cells[i].value as DateCellValue;
          const dateInfo = getFormattedDate('M d', parseInt(date.timestamp, 10)).split(' ');

          textItemsGroup.insertAt(
            textItemsGroup.getObjects().length,
            new IText(dateInfo[0].toUpperCase(), {
              dataType: CellType.MONTH,
              padding: w,
              _fontSizeMult: v,
              editable: false,
            })
          );
          textItemsGroup.insertAt(
            textItemsGroup.getObjects().length,
            new IText(dateInfo[1], {
              dataType: CellType.DATE,
              padding: w,
              _fontSizeMult: v,
              editable: false,
            })
          );
        } else if (cells[i].type === CellType.VENUE) {
          const cellValue = cells[i].getValue() as string;
          const venueText = cellValue ? `- ${cellValue} -` : cellValue;
          textItemsGroup.insertAt(
            textItemsGroup.getObjects().length,
            new IText(venueText, {
              dataType: cells[i].type,
              _fontSizeMult: v,
              editable: false,
            })
          );
        } else if (cells[i].type === CellType.TEAM) {
          const cellValue = cells[i].getValue() as string;
          textItemsGroup.insertAt(
            0,
            new IText(cellValue, {
              dataType: cells[i].type,
              _fontSizeMult: v,
              editable: false,
            })
          );
        }
      }
      fabricRowItems.push(textItemsGroup);
    }

    this.item.fabricObject.removeAll();
    this.addLayoutItemsToGroupWithOriginalScale(fabricRowItems);
  }

  /**
   * Sets styles for highlighted items in the table/schedule
   * @override
   */
  setStylesForHighlightedItems(): void {
    const {highlightedRows} = this.item.fabricObject;
    const objects = this.item.fabricObject.getObjects() as Group[];
    const color = rgbaToHexString(this.item.highlightedTextColor);

    if (highlightedRows.length > 0) {
      for (let i = 0; i < highlightedRows.length; i++) {
        const item = objects[highlightedRows[i]];
        const obs = item.getObjects();

        for (let x = 0; x < obs.length; x++) {
          obs[x].set({
            fill: color,
            stroke: this.item.textStyles.stroke ? rgbaToHexString(this.item.textStyles.strokeColor) : color,
          });
        }
      }
    }
  }

  /**
   * Sets the styles on items inside the view, specific to this layout.
   * @private
   */
  setViewStyles(): void {
    applyToChildObjects(this.item.fabricObject, {
      lineHeight: (this.item.textStyles.leading - 30) / 113,
    });

    const fontSize2 = this.item.textStyles.fontSize * 2;
    let fontSize3 = this.item.textStyles.fontSize * 0.3;
    let strokeColor = rgbaToHexString(this.item.textStyles.fill.fillColor[0]);
    const paintFirst = this.item.textStyles.stroke ? 'stroke' : 'fill';
    const isBoldApplied = isBoldVariationAvaliableForFont(this.item.textStyles.fontFamily);
    const isItalicApplied = isItalicVariationAvaliableForFont(this.item.textStyles.fontFamily);

    // 8 is the minimum font size
    if (fontSize3 < 8) {
      fontSize3 = 8;
    }

    const groups = this.item.fabricObject.getObjects() as Group[];
    let strokeWidth = this.getScaledStrokeWidth(this.item, this.strokeWidth);
    let strokeWidthForMonth = strokeWidth;
    let strokeWidthForDate = strokeWidth * 2;

    if (this.item.textStyles.isBold) {
      strokeWidth = !isBoldApplied ? BOLD_STROKE_WIDTH_FACTOR * fontSize3 : 0;
      strokeWidthForMonth += !isBoldApplied ? strokeWidthForMonth * 1.5 : 0;
      strokeWidthForDate += !isBoldApplied ? strokeWidthForDate * 1.5 : 0;
    } else if (this.item.textStyles.stroke) {
      strokeWidth = TEXT_OUTLINE_STROKE_WIDTH_FACTOR * fontSize3 * this.item.textStyles.strokeWidth;
      strokeWidthForMonth += fontSize2 * TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.strokeWidth;
      strokeWidthForDate += fontSize2 * TEXT_OUTLINE_STROKE_WIDTH_FACTOR * this.item.textStyles.strokeWidth;
      strokeColor = rgbaToHexString(this.item.textStyles.strokeColor);
    }
    for (let i = 0; i < groups.length; i++) {
      const groupItems = groups[i].getObjects();
      for (let x = 0; x < groupItems.length; x++) {
        groupItems[x].set({
          fontStyle: !isItalicApplied && this.item.textStyles.isItalic ? 'italic' : 'normal',
          underline: this.item.textStyles.underLine,
          linethrough: this.item.textStyles.lineThrough,
        });
        // @ts-expect-error custom property in pmw fabric
        switch (groupItems[x].dataType) {
          case CellType.MONTH:
            groupItems[x].set({
              fontSize: this.item.textStyles.fontSize,
              strokeWidth: strokeWidthForMonth,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
            });
            break;
          case CellType.DATE:
            groupItems[x].set({
              fontSize: fontSize2,
              strokeWidth: strokeWidthForDate,
              strokeLineJoin: 'round',
              paintFirst,
              stroke: strokeColor,
            });
            break;
          case CellType.TEAM:
          case CellType.VENUE:
            groupItems[x].set({
              fontSize: fontSize3,
              textAlign: 'center',
            });
            if (strokeWidth) {
              groupItems[x].set({
                strokeWidth,
                strokeLineJoin: 'round',
                paintFirst,
                stroke: strokeColor,
              });
            }
            break;
          default:
            // @ts-expect-error custom property in pmw fabric
            throw new Error(`Undhanled dataType ${groupItems[x].dataType}`);
        }
      }
    }
  }

  /**
   * Position the text items inside the groups and reset each group dimensions.
   */
  layoutItemsInsideGroups(): void {
    const groups = this.item.fabricObject.getObjects() as Group[];
    for (const group of groups) {
      const newDim = this.getNewViewDimensions(group, 'horizontal');
      group.set({
        width: newDim.width,
        height: newDim.height,
        left: 0,
        top: 0,
      });
      this.horizontallyStackItems(group, this.item.ySpacing, 0);
      this.horizontallyCenterItems(group);
    }
  }
}
